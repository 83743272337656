@import "~medium-editor/dist/css/medium-editor";
@import "~medium-editor/dist/css/themes/beagle";
@import "entrypoint_init";

.medium-editor-element {
  @include el-border;

  border-radius: $border-radius-lg;
  min-height: 100px;
  padding: 10px;

  @include media-breakpoint-between(xs, md) {
    overflow-y: scroll;
  }
}

.medium-editor-placeholder {
  &::after {
    white-space: normal;
  }
}

.medium-editor-toolbar {
  z-index: $zindex-tooltip;
}
